@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Exo';
	src: url('RESOURCE/fonts/Exo-Bold.eot');
	src: url('RESOURCE/fonts/Exo-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Exo-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Exo-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Exo-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Exo';
	src: url('RESOURCE/fonts/Exo-Light.eot');
	src: url('RESOURCE/fonts/Exo-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Exo-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Exo-Light.woff') format('woff'),
		url('RESOURCE/fonts/Exo-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Exo';
	src: url('RESOURCE/fonts/Exo-Medium.eot');
	src: url('RESOURCE/fonts/Exo-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Exo-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Exo-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Exo-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Exo';
	src: url('RESOURCE/fonts/Exo-Regular.eot');
	src: url('RESOURCE/fonts/Exo-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Exo-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Exo-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Exo-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('RESOURCE/fonts/Inter-Regular.eot');
	src: url('RESOURCE/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Inter-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Inter-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('RESOURCE/fonts/Inter-ExtraBold.eot');
	src: url('RESOURCE/fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Inter-ExtraBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Inter-ExtraBold.woff') format('woff'),
		url('RESOURCE/fonts/Inter-ExtraBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('RESOURCE/fonts/Inter-Light.eot');
	src: url('RESOURCE/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Inter-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Inter-Light.woff') format('woff'),
		url('RESOURCE/fonts/Inter-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('RESOURCE/fonts/Inter-Medium.eot');
	src: url('RESOURCE/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Inter-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Inter-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}