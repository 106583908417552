.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);
		padding: 45px 0px;
		position: relative;
		font-size: 14px;

		.container {
			max-width: 1280px;
		}

		.first-col {
			padding-right: 9%;

			p.light {
				color: #FFFFFFCF;
				font-size: 14px;
			}

			p {
				font-size: 15px;
			}
		}

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}




		.logo {
			width: 160px;
			height: auto;

		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
		padding: 10px 0;

		.container {
			max-width: 1280px;
		}

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {

			@media(min-width: 993px) {
				text-align: right;
			}

			@media(max-width: 993px) {
				text-align: left;
			}
		}

	}

}